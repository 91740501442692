import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  getIdTokenResult,
} from 'firebase/auth'

// https://firebase.google.com/docs/reference/js/auth.md#auth_package

// @TODO move to config file
const firebaseConfig = {
  apiKey:
    process.env.VUE_APP_FB_APIKEY || 'AIzaSyDNJm5HcPBbbBA73J7zQy_-vl60ScbPmIY',
  authDomain:
    process.env.VUE_APP_FB_AUTHDOMAIN || 'teamculture-sandbox.firebaseapp.com',
  projectId: process.env.VUE_APP_FB_PROJECTID || 'teamculture-sandbox',
  storageBucket:
    process.env.VUE_APP_FB_STORAGE || 'teamculture-sandbox.appspot.com',
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING || '355976593553',
  appId:
    process.env.VUE_APP_FB_APPID || '1:355976593553:web:04dae7ad7663d8ab0b3f30',
  measurementId: process.env.VUE_APP_FB_MEASUREMENT || 'G-3G5T3H173B',
}

// init firebase
initializeApp(firebaseConfig)

const projectAuth = getAuth()
projectAuth.useDeviceLanguage()

export {
  projectAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  getIdTokenResult,
}
