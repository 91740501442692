import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router/Routes'
import storeConfig from './store/index'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueApexCharts from 'vue-apexcharts'
import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { projectAuth } from '@/firebase/config'

Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(Vuex)

const store = new Vuex.Store(storeConfig)

Vue.component('treeselect', TreeSelect)

let app = ''
// initialize app after firebase setup
projectAuth.onAuthStateChanged(async () => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      i18n,
      VueApexCharts,
      render: (h) => h(App),
    }).$mount('#app')
  }
})

if (window.Cypress) {
  // be visible only during e2e tests
  window.app = app
}
