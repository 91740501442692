import Vue from 'vue'
import currentUser from './modules/currentUser'

export default {
  state: {
    client: {
      ...JSON.parse(sessionStorage.getItem('client') || '{}'),
      paymentMethod: 'card',
      package: 'free', // free, basic, advanced, enterprise
    },
    groupId: '',
    currentTab: '',
    updateYourPackageDialog: {
      isOpen: false,
      type: 'action',
    },
    explanationNPS: {
      isOpen: false,
      type: 'lnps',
    },
  },
  mutations: {
    closeUpdateYourPackageDialog(state) {
      state.updateYourPackageDialog.isOpen = false
    },
    openUpdateYourPackageDialog(state, type) {
      state.updateYourPackageDialog.isOpen = true
      state.updateYourPackageDialog.type = type
    },
    closeExplanationNPSDialog(state) {
      state.explanationNPS.isOpen = false
    },
    openExplanationNPSDialog(state, type) {
      state.explanationNPS.isOpen = true
      state.explanationNPS.type = type
    },
    changeClientPackage(state, payload) {
      Vue.set(state.client, 'package', payload)
    },
    setGroupId(state, payload) {
      Vue.set(state, 'groupId', payload)
    },
    setCurrentTab(state, payload) {
      Vue.set(state, 'currentTab', payload)
    },
  },
  actions: {},
  getters: {
    getPaymentMethod: (state) => state.client.paymentMethod,
    getUpdateYourPackageStatus: (state) => state.updateYourPackageDialog.isOpen,
    getUpdateYourPackageType: (state) => state.updateYourPackageDialog.type,
    getExplanationNPS_Status: (state) => state.explanationNPS.isOpen,
    getExplanationNPSType: (state) => state.explanationNPS.type,
    getClientPackage: (state) => state.client.package,
    getGroupId: (state) => state.groupId,
    getClientPermissions: (state) => state.client.permissions,
    getCurrentTab: (state) => state.currentTab,
  },
  modules: {
    currentUser,
  },
}
