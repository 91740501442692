import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import variables from '@/styles/scss/_colors.scss'
import IconSliderHand from './custom-icons/slider-hand.vue'
import IconThreeDots from './custom-icons/three-dots.vue'
import IconStarRounded from './custom-icons/star-rounded.vue'
import IconStarRoundedOutline from './custom-icons/star-rounded-outline.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        ...variables,
      },
    },
  },
  icons: {
    values: {
      sliderHand: {
        component: IconSliderHand,
      },
      threeDotsOvalRounded: {
        component: IconThreeDots,
      },
      starRounded: {
        component: IconStarRounded,
      },
      starRoundedOutline: {
        component: IconStarRoundedOutline,
      },
    },
  },
})
