import Vue from 'vue'
import { getIdTokenResult } from '@/firebase/config'

// initial state
function initialState() {
  return {
    uid: null,
    name: null,
    email: null,
    photoURL: null,
    token: null,
  }
}

const state = initialState()

// getters
const getters = {
  user: (state) => state.user,
}

// actions
const actions = {
  loadCurrentUser({ commit }, user) {
    commit('LOAD_CURRENT_USER', user)
  },
}

// mutations
const mutations = {
  async LOAD_CURRENT_USER(state, user) {
    const { uid, displayName, email, photoURL } = user

    Vue.set(state, 'uid', uid)
    Vue.set(state, 'name', displayName)
    Vue.set(state, 'email', email)
    Vue.set(state, 'photoURL', photoURL)

    if (user.uid) {
      const tokenResult = await getIdTokenResult(user, true)
      Vue.set(state, 'token', tokenResult.token)
      Vue.set(state, 'tenantID', tokenResult.claims.tenantID)
      Vue.set(state, 'plan', tokenResult.claims.plan)
      Vue.set(state, 'permissions', tokenResult.claims.permissions)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
